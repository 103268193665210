import React from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

import { Container, MainTitle } from 'styles/globalStyles';
import { RegulationsPreWrappedBox } from './style';

const RegulationsPage = ({ t }) => {
  return (
    <Container>
      <MainTitle as="h1">{t('Regulations')}</MainTitle>
      <RegulationsPreWrappedBox>
        <p>
          1.
          <a
            href={`/regulationsIndividual${i18n.language.toUpperCase()}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('RegulationsIndividual')}
          </a>
        </p>
        {/* <p>
          2.
          <a
            href={`/regulationsCompany${currentLang.toUpperCase()}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('RegulationsCompany')}
          </a>
        </p> */}
      </RegulationsPreWrappedBox>
    </Container>
  );
};

export default withTranslation('regulations')(RegulationsPage);
