import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { breakpoint } from 'styles/breakpoints';

export const Card = styled(Link)`
  display: block;
  overflow: hidden;
  flex: 0 0 100%;
  padding: 10px;
  text-decoration: none;
  text-align: left;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.01);
  }

  @media ${breakpoint.xs} {
    flex-basis: 100%;
    height: 192px;
  }

  @media (min-width: 540px) {
    flex-basis: 100%;
    height: 250px;
  }
  @media ${breakpoint.md} {
    flex-basis: 50%;
    height: 362px;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url('${props => props.image}');
  padding: 18px 0 0 18px;


  @media (min-width: 1000px) {
    padding: 49px 0 0 49px;
  }
`;

export const RoomName = styled.div`
  font-weight: 900;
  font-size: 36px;
  color: #ffffff;
  line-height: 38px;
  width: 100%;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.6);

  @media screen and (max-width: 40em) {
    font-size: 22px;
    line-height: 22px;
  }
`;

export const RoomCapacity = styled.div`
  font-size: 18px;
  color: #ffffff;
  font-weight: 900;
  line-height: 24px;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.6);
  padding-top: 7px;
`;

export const DiscountInfo = styled.div`
  font-size: 14px;
  font-weight: 900;
  line-height: 16px;
  position: absolute;
  bottom: 0;
  color: #ffffff;
  text-align: left;
  padding-bottom: 15px;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  max-width: 200px;
  @media ${breakpoint.md} {
    padding-bottom: 25px;
    max-width: none;
  }
`;

export const RoomPrice = styled.div`
  font-size: 14px;
  font-weight: 900;
  line-height: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #ffffff;
  text-align: right;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  border-radius: 40px 0px 0px 0px;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  padding: 15px 15px 11px;
  span {
    font-weight: 400;
    font-size: 10px;
  }
  @media ${breakpoint.md} {
    padding: 25px;
  }
`;

export const StyledLink = styled.button`
  padding: 0 0 15px;
  margin: 0;
  background-color: transparent;
  border: none;
  font-size: 12px;
  margin-right: 90px;
  font-weight: 900;
  line-height: 16px;
  position: absolute;
  bottom: 0;
  color: #ffffff;
  text-align: left;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  text-decoration: none;
  cursor: pointer;

  @media ${breakpoint.md} {
    margin-right: 130px;
    font-size: 14px;
  }

  &:hover {
    color: #ffffff;
  }
  &:focus {
    outline: none;
  }
`;
