import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from 'routes';
import { Container } from 'styles/globalStyles';

import {
  Wrapper,
  Top,
  Links,
  FooterLink,
  Contact,
  Copyright,
  Content,
  ContentWrapper,
  Col,
} from 'components/Footer/style';

const Footer = ({
  history: {
    location: { pathname },
  },
}) => {
  const { t } = useTranslation('footer');
  const isOnHomepage = useMemo(() => pathname === '/', [pathname]);
  if (pathname.indexOf('auth') !== -1) return null;

  return (
    <Wrapper isOnHomepage={isOnHomepage}>
      <Top isOnHomepage={isOnHomepage}>
        <ContentWrapper>
          <Links>
            <li>
              <FooterLink to={routes.regulations}>
                {t('termsAndConditions')}
              </FooterLink>
            </li>
            <li>
              <FooterLink to={routes.privacyPolicy}>
                {t('privacyPolicy')}
              </FooterLink>
            </li>
            {/* <li>
              <FooterLink to={routes.investorRelations}>
                {t('Investor Relations')}
              </FooterLink>
            </li> */}
            <li>
              <FooterLink to={routes.contact}>{t('Contact')}</FooterLink>
            </li>
          </Links>

          <Contact>
            <a href="mailto:tanzraumecom@gmail.com">
              <img src="/images/icons/at.svg" alt="" />
              tanzraumecom@gmail.com
            </a>
            <a
              href="https://www.facebook.com/tanzraeumecom"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/icons/fb.svg" alt="" />
              tanzraeumecom
            </a>
            <p>
              Tanzraeume.com MH GmbH
              <br />
              Lise-Meitner-Straße 39/41
              <br />
              10589 Berlin
              <br />
            </p>
          </Contact>
        </ContentWrapper>
      </Top>
      <Copyright>
        <Container>
          <Content>
            <Col>
              Copyright tanzraume.com&nbsp;
              {new Date().getFullYear()}
            </Col>
          </Content>
        </Container>
      </Copyright>
    </Wrapper>
  );
};

Footer.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Footer);
