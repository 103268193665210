import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
// import i18n from 'i18n';
import { Container } from 'styles/globalStyles';
// import routes from 'routes';
import { Close, ButtonText, Wrapper } from './style';

const DiscountBanner = ({ t, location, getDiscount }) => {
  const [visible, setVisibility] = useState(false);
  useEffect(() => {
    if (
      location.pathname !== '/' ||
      window.matchMedia('(max-width: 768px)').matches
    ) {
      setTimeout(() => {
        setVisibility(true);
      }, 100);
    }
  }, []);

  useEffect(() => {
    setVisibility(location.pathname !== '/');
  }, [location.pathname]);

  const clickHandler = async () => {
    try {
      await getDiscount();
      setVisibility(false);
      // if (i18n.language === 'pl') {
      //   const win = window.open(routes.percent, '_blank');

      //   win.focus();
      // } else {
      const win = window.open(
        'https://www.facebook.com/reel/822889312106053',
        '_blank',
      );

      win.focus();
      // }
      window.location.reload();
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <Wrapper visible={visible}>
      <Container>
        <Close
          type="button"
          onClick={() => {
            setVisibility(false);
          }}
        />
        {/* {i18n.language === 'pl' ? (
          <ButtonText
            type="button"
            onClick={clickHandler}
            dangerouslySetInnerHTML={{ __html: t('percent') }}
          />
        ) : ( */}
        <ButtonText
          type="button"
          onClick={clickHandler}
          dangerouslySetInnerHTML={{ __html: t('facebook') }}
        />
        {/* )} */}
      </Container>
    </Wrapper>
  );
};

DiscountBanner.propTypes = {
  t: PropTypes.func.isRequired,
  getDiscount: PropTypes.func.isRequired,
};

const enhance = compose(withTranslation('discount'), withRouter);

export default enhance(DiscountBanner);
