import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionWrapper,
  ActionText,
  ActionLink,
  FullHeightFlex,
  MainImg,
  MainName,
  FormBox,
} from './style';

export const LoginLayoutAction = ({ text, linkText, positionabs, to }) => {
  return (
    <ActionWrapper>
      <ActionText>
        {text}
        <ActionLink to={to} positionabs={positionabs}>
          {linkText}
        </ActionLink>
      </ActionText>
    </ActionWrapper>
  );
};

const AuthLayout = ({ image, children }) => {
  return (
    <FullHeightFlex>
      <MainImg src={image} alt="" />
      <MainName to="/">Tanzräume</MainName>
      <FormBox>{children}</FormBox>
    </FullHeightFlex>
  );
};

AuthLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthLayout;
