/* eslint-disable no-nested-ternary */
import times from 'lodash/times';
import i18n from 'i18n';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/pl.json'));
countries.registerLocale(require('i18n-iso-countries/langs/uk.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const generateCapacity = () =>
  times(9, n => n + 1)
    .map(n => ({
      value: n.toString(),
      label: i18n.t('person', { count: n }),
    }))
    .concat([
      {
        value: '10',
        label: i18n.t('personAndMore', { count: 10 }),
      },
    ]);

export const capacity = () => {
  let values = generateCapacity();

  i18n.on('languageChanged', () => {
    values = generateCapacity();
  });

  return values;
};

export const timeParser = timeInMinutes => {
  const hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;

  let finalHours = '';
  let finalMinutes = '';

  if (hours) {
    finalHours = i18n.t('promotions:hour', { count: hours });
  }
  if (minutes) {
    finalMinutes = `${minutes} ${i18n.t('promotions:minutes')}`;
  }
  const finalTime = `${finalHours} ${finalMinutes}`;
  return finalTime;
};

export const premiumDurationOptions = [
  { value: 'short', label: i18n.t('short') },
  { value: 'long', label: i18n.t('long') },
];

export const currencyFormat = {
  PLN: 'zł',
  EUR: '€',
};

export const COUNTRY_CODES = () => {
  const currentLang = i18n.language === 'ua' ? 'uk' : i18n.language;

  let countriesList = countries.getNames(currentLang, { select: 'official' });

  i18n.on('languageChanged', () => {
    countriesList = countries.getNames(currentLang, { select: 'official' });
  });

  return countriesList;
};
