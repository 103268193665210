import styled from '@emotion/styled';
import { PreWrappedBox } from 'styles/globalStyles';

export const PivacyPolicyPreWrappedBox = styled(PreWrappedBox)`
  line-height: 20px;
  white-space: pre-line;
  overflow-wrap: break-word;
  h3 {
    font-weight: bold;
  }

  ul {
    list-style: disc;
    padding-left: 30px;
  }

  ol {
    list-style: lower-alpha;
    padding-left: 30px;
    li {
      font-weight: bold;
    }
  }
`;
