import React, { useState, useRef } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Flex, Box } from 'rebass';
import Modal from 'components/Modal';
import PreferencesModal from './components/PreferencesModal';
import * as Styled from './style';

const CookieBanner = () => {
  const { t } = useTranslation('cookies');
  const [modalIsVisible, setModalVisibility] = useState(false);
  const cookieConsentRef = useRef();

  const handleAcceptButtonClick = () => {
    Object.entries({
      cookiesEssential: true,
      cookiesAnalysis: true,
    }).forEach(([key, value]) => {
      Cookies.set(`DE-${key}`, value);
    });
    return cookieConsentRef?.current?.accept?.();
  };

  const handleDeclineButtonClick = () => {
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
    Object.entries({
      cookiesEssential: true,
      cookiesAnalysis: false,
    }).forEach(([key, value]) => {
      Cookies.set(`DE-${key}`, value);
    });
    return cookieConsentRef?.current?.decline?.();
  };
  return (
    <CookieConsent
      buttonStyle={{ display: 'none' }}
      style={{ background: 'rgba(0, 0, 0, 0.7)' }}
      buttonText={t('button')}
      ref={cookieConsentRef}
      cookieName="DE-BallroomCookieConsent"
    >
      <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Box overerflowWrap="wrap" width={[1, 1, 1, 1, 1 / 2, 1 / 2, 2 / 3]}>
          <Trans i18nKey="cookies:This website uses cookies to enhance the user experience.">
            This website uses cookies to enhance the user experience.If you
            select “Accept All”, you accept this fact and consent to the
            processing by us this information. If you decline, we will only use
            the necessary files cookie. Select “Preference Settings” to view
            details and manage options. For more information, see our
            <RouterLink
              target="_blank"
              to="/privacy-policy"
              style={{ color: '#d9a248', textDecoration: 'none' }}
            >
              polityką prywatności
            </RouterLink>
            .
          </Trans>
        </Box>
        <Flex
          sx={{ gap: '10px' }}
          mt={['10px', '10px', '10px', '10px', 0]}
          flexWrap="wrap"
        >
          <Styled.ButtonAsLink
            type="button"
            onClick={() => handleDeclineButtonClick()}
          >
            {t('declineButton')}
          </Styled.ButtonAsLink>
          <Styled.ButtonAsLink
            type="button"
            onClick={() => setModalVisibility(true)}
          >
            {t('modifyPreferences')}
          </Styled.ButtonAsLink>
          <Button
            type="button"
            onClick={() => handleAcceptButtonClick()}
            sx={{
              backgroundColor: '#d9a248',
              color: 'white',
              fontSize: '16px',
              borderRadius: '3px',
              padding: '10px',
              fontWeight: '500',
            }}
          >
            {t('button')}
          </Button>
        </Flex>
      </Flex>
      <Modal
        opened={modalIsVisible}
        close={() => {
          setModalVisibility(false);
        }}
      >
        <PreferencesModal acceptCookies={() => handleAcceptButtonClick()} />
      </Modal>
    </CookieConsent>
  );
};

export default CookieBanner;
