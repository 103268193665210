import styled from '@emotion/styled';
import { PreWrappedBox } from 'styles/globalStyles';

export const RegulationsPreWrappedBox = styled(PreWrappedBox)`
  line-height: 20px;
  white-space: pre-line;

  dl {
    margin: 0 0 0 10px;
    padding: 0 0 0 10px;
    background-color: #efefef;
    dt {
      font-weight: bold;
    }
  }

  h3 {
    font-weight: bold;
  }

  li {
    .lower-alpha {
      list-style-type: lower-alpha;
    }
    .lower-roman {
      list-style-type: lower-roman;
    }
  }

  ol {
    list-style: decimal;
    padding-left: 30px;
    .lower-alpha {
      list-style-type: lower-alpha;
    }
  }

  table {
    table-layout: fixed;
    text-align: center;
    width: 100%;
    tr {
      overflow-wrap: break-word;
    }
    th {
      background-color: #efefef;
      border: 1px solid black;
      padding: 10px;
    }
    td {
      background-color: white;
      border: 1px solid black;
      padding: 10px;
    }
  }

  ul {
    list-style: disc;
    padding-left: 30px;
  }
`;
