import axios from 'axios';
import i18n from 'i18n';
import { authStore } from '../stores';

const apiUrl = process.env.REACT_APP_API_URL;
export const imageBaseUrl = apiUrl;
const API = axios.create({
  baseURL: apiUrl,
});

const AUTH_TOKEN = localStorage.getItem('auth-token');
if (AUTH_TOKEN) {
  authStore.signInWithSession(AUTH_TOKEN);
  API.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
}

API.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      authStore.logout();
    }
    return Promise.reject(error.response.data);
  },
);

const setAuthenticationToken = token => {
  localStorage.setItem('auth-token', token);
  API.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearAuthenticationToken = () => {
  localStorage.removeItem('auth-token');
  API.defaults.headers.common.Authorization = null;
};

// change language token
API.defaults.headers.common['Accept-Language'] = [
  'pl',
  'ua',
  'en',
  'de',
  'es',
].includes(i18n.language)
  ? i18n.language
  : 'de';
i18n.on('languageChanged', lng => {
  API.defaults.headers.common['Accept-Language'] = [
    'pl',
    'ua',
    'en',
    'de',
    'es',
  ].includes(lng)
    ? lng
    : 'de';
});

const APIRoutes = {
  auth: {
    signIn: '/auth/sign_in',
    signUp: '/auth/sign_up',
    passwordReset: token =>
      token ? `/password_reset/${token}` : '/password_reset',
  },
  discounts: {
    fb: '/discounts/facebookShare',
  },
  rooms: {
    list: '/inventory',
    listByAddress: '/inventory/by-location',
    similar: '/inventory/similar_options',
    details: id => `/inventory/s/${id}`,
  },
  bookings: {
    list: '/bookings',
    details: id => `/bookings/${id}`,
    update: bookingId => `/bookings/${bookingId}`,
    reschedule: bookingId => `bookings/${bookingId}/reschedule`, // PATCH
    cancelReservation: bookingId => `/bookings/${bookingId}/cancel`,
  },
  orders: {
    create: '/orders/add_to_cart', // POST
    details: id => `/orders/${id}`,
    submit: '/orders/submit',
  },
  dropdowns: {
    cities: '/inventory/used_cities',
  },
  addresses: {
    details: id => `/addresses/${id}`,
  },
  user: {
    create: '/users', // POST
    profile: '/users/me', // GEY
    update: '/users/me/update_profile', // PATCH
    remove: id => `/users/${id}`, // DELETE
    changePassword: '/users/me/change_password', // PATCH
    bookings: '/orders/my_orders?mode=bookings',
    orders: `/orders/my_orders?mode=orders`,
    deleteProfile: '/users/me', // DELETE
  },
  emails: {
    contact: '/emails/contact', // POST
  },
  promotions: '/promotions',
  buyPremium: '/promotions/purchase_premium_account',
  buyPromotion: pid => `/promotions/${pid}`,
  blogPosts: '/blog_posts',
  blogPost: slug => `/blog_posts/s/${slug}`,
  products: '/products',
  product: slug => `/products/s/${slug}`,
};

export { API, APIRoutes, setAuthenticationToken, clearAuthenticationToken };
