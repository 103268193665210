import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment-timezone';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'moment/min/locales';
import en from './translations/en';
import pl from './translations/pl';
import de from './translations/de';
import ua from './translations/ua';
import es from './translations/es';

const languageDetector = new LanguageDetector();

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      de,
      en,
      pl,
      ua,
      es,
    },
    fallbackLng: 'en',
    debug: true,
    detection: {
      order: ['querystring', 'localStorage'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'ballroom:language',
      caches: ['localStorage'],
    },

    // have a common namespace used around the full app
    ns: ['common', 'navbar'],
    defaultNS: 'common',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });

export const availableLanguages = [
  { value: 'de', label: 'DE' },
  { value: 'en', label: 'EN' },
  { value: 'pl', label: 'PL' },
  { value: 'ua', label: 'UA' },
  { value: 'es', label: 'ES' },
];
const currentLang = i18n.language === 'ua' ? 'uk' : i18n.language;
moment.locale(currentLang);

i18n.on('languageChanged', lng => {
  const language = lng === 'ua' ? 'uk' : lng;
  moment.locale(language);
});

export default i18n;
