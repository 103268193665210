import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';

import { imagePath } from 'utils/imagePathHelper';
import isEqual from 'lodash/isEqual';
import i18n from 'i18n';

import Layout from 'components/Search/Layout';
import MainButton from 'components/MainButton';
import IconDetail from 'components/IconDetail';
import ImageSlider from 'components/ImageSlider';
import NotFound from 'components/NotFound';
import {
  StarText,
  AdditionalInfo,
  Surface,
  SurfaceBorder,
} from 'styles/globalStyles';
import { breakpoint } from 'styles/breakpoints';
import Preloader from 'components/Preloader';
import { currencyFormat } from 'utils/staticValues';
import * as Styled from './style';

@withRouter
@inject('searchStore', 'addressStore', 'bookingStore')
@observer
class RoomDetailsPage extends Component {
  state = {
    queryParams: {},
    infoPage: false,
  };

  componentDidMount = async () => {
    await this.fetchRoom();
    this.setQueryParams();
  };

  componentWillUnmount = () => {
    const {
      searchStore: { reset },
    } = this.props;

    reset();
  };

  componentDidUpdate = async prevProps => {
    const {
      match: {
        params: { roomId },
      },
    } = this.props;
    if (!isEqual(prevProps.match.params.roomId, roomId)) {
      await this.fetchRoom();
      this.setQueryParams();
    }
  };

  setQueryParams = () => {
    const {
      location: { search },
    } = this.props;
    const parsedParams = queryString.parse(search);
    this.setState({ queryParams: parsedParams });
    if (parsedParams.info) {
      this.setState({ infoPage: true });
    }
  };

  fetchRoom = async () => {
    const {
      match: {
        params: { roomId },
      },
      searchStore: { fetchRoomBySlug },
    } = this.props;

    await fetchRoomBySlug(roomId);
  };

  showBookingButton = () => {
    const { queryParams } = this.state;

    return !!(
      queryParams &&
      queryParams.from &&
      queryParams.to &&
      queryParams.date &&
      queryParams.city
    );
  };

  render() {
    const {
      location: { search },
      searchStore: { currentRoom, error, notFound },
      bookingStore: { startBooking },
      t,
    } = this.props;

    const { queryParams, infoPage } = this.state;

    if (notFound) return <NotFound message={error.message} />;
    if (isEmpty(currentRoom)) return <Preloader h={200} />;

    let { name } = currentRoom;
    if (currentRoom[i18n.language] && currentRoom[i18n.language].name) {
      name = currentRoom[i18n.language].name;
    }

    return (
      <Layout hideFilters={infoPage}>
        <Styled.Wrapper>
          <ImageSlider
            title={name}
            price={
              currentRoom.priceData ? currentRoom.priceData.price / 100 : 0
            } // tmp fix
            currency={
              currentRoom.priceData &&
              currencyFormat[currentRoom.priceData.currency]
            }
            images={currentRoom.images}
            hidePrice={infoPage}
          />
          <Styled.Row>
            <Styled.LeftColumn>
              <Styled.Details>
                <Surface>
                  {currentRoom.surfaceArea} m<sup>2</sup>
                  <SurfaceBorder />
                </Surface>

                <IconDetail
                  mb={[2, 2, 2, 2, 3]}
                  icon="/images/icons/addressIcon.svg"
                  text={`${currentRoom.location.city}, ${currentRoom.location.street}`}
                  textSize="big"
                />

                {currentRoom[i18n.language] &&
                  currentRoom[i18n.language].additionalLocationInfo && (
                    <AdditionalInfo
                      dangerouslySetInnerHTML={{
                        __html:
                          currentRoom[i18n.language].additionalLocationInfo,
                      }}
                    />
                  )}
                {currentRoom.capacity && (
                  <IconDetail
                    mb={[2, 2, 2, 2, 3]}
                    icon="/images/icons/capacityIcon.svg"
                    label="max_people"
                    text={t('person', { count: currentRoom.capacity })}
                    textSize="big"
                  />
                )}
                {!isEmpty(queryParams) &&
                  queryParams.date &&
                  queryParams.from &&
                  queryParams.to && (
                    <IconDetail
                      mb={[2, 2, 2, 2, 3]}
                      icon="/images/icons/availabilityIcon.svg"
                      label="available"
                      text={`${queryParams.date} / ${queryParams.from} - ${queryParams.to}`}
                      textSize="big"
                    />
                  )}
                {!infoPage ? <StarText>{t('starInfo')}</StarText> : null}
                <Styled.MyLineSeparator />
                {/* {currentRoom[i18n.language] &&
                  currentRoom[i18n.language].additionalLocationInfo && (
                    <Styled.Description>
                      {currentRoom[i18n.language].additionalLocationInfo}
                    </Styled.Description>
                  )} */}
              </Styled.Details>
              {this.showBookingButton() && (
                <MainButton
                  width="312px"
                  onClick={() => startBooking(currentRoom.slug, search)}
                  type="button"
                  sx={{
                    [`@media ${breakpoint.xs}`]: {
                      display: 'none !important',
                    },
                    [`@media ${breakpoint.md}`]: {
                      display: 'block !important',
                    },
                  }}
                >
                  {t('buttons:Book')}
                </MainButton>
              )}
            </Styled.LeftColumn>

            <Styled.RightColumn>
              {currentRoom.mapImage && (
                <Styled.MapImage src={imagePath(currentRoom.mapImage.src)} />
              )}
              {this.showBookingButton() && (
                <MainButton
                  mt={25}
                  onClick={() => startBooking(currentRoom.slug, search)}
                  type="button"
                  sx={{
                    [`@media ${breakpoint.xs}`]: {
                      display: 'block !important',
                    },
                    [`@media ${breakpoint.md}`]: {
                      display: 'none !important',
                    },
                  }}
                >
                  {t('buttons:Book')}
                </MainButton>
              )}
            </Styled.RightColumn>
          </Styled.Row>
        </Styled.Wrapper>
      </Layout>
    );
  }
}

RoomDetailsPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(RoomDetailsPage);
