import React, { useState } from 'react';
import { Heading, Text, Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import Checkbox from 'components/Checkbox';
import { required } from 'utils/formValidators';
import Preloader from 'components/Preloader';
import MainButton from 'components/MainButton';
import { Cookies } from 'react-cookie-consent';
import Collapsible from 'react-collapsible';
import * as Styled from '../style';

const PreferencesModal = ({ close, acceptCookies }) => {
  const { t } = useTranslation('cookies');
  const [detailsVisible, setDetailsVisible] = useState(false);

  const setCookies = values => {
    Object.entries(values).forEach(([key, value]) => {
      Cookies.set(`DE-${key}`, value);
    });
    if (values.cookiesAnalysis) {
      Cookies.remove('_ga');
      Cookies.remove('_gat');
      Cookies.remove('_gid');
    }
    acceptCookies();
    close();
  };

  return (
    <>
      <Heading
        as="h2"
        fontWeight="medium"
        fontSize={[3, 3, 3, 6]}
        mb={3}
        pr={['30px', '30px', '30px', 0]}
      >
        {t('Preferences')}
      </Heading>
      <Text
        lineHeight={['14px', '14px', '14px', '18px']}
        fontSize={[0, 0, 0, 3]}
      >
        {t('Manage preferences')}
      </Text>
      <Box>
        <Form
          onSubmit={v => setCookies(v)}
          initialValues={{ cookiesEssential: true, cookiesAnalysis: true }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              {submitting && <Preloader cover />}
              <Field
                label={t('Essential')}
                name="cookiesEssential"
                type="checkbox"
                disabled
                component={Checkbox}
                validate={required}
              />
              <Flex>
                <Field
                  label={t('Analysis')}
                  name="cookiesAnalysis"
                  type="checkbox"
                  component={Checkbox}
                />
                <Styled.ButtonAsLink
                  style={{
                    color: '#3e291c',
                    fontSize: '14px',
                    marginTop: '20px',
                  }}
                  type="button"
                  onClick={() =>
                    detailsVisible
                      ? setDetailsVisible(false)
                      : setDetailsVisible(true)
                  }
                >
                  &nbsp;{detailsVisible ? t('readLess') : t('readMore')}
                </Styled.ButtonAsLink>
              </Flex>
              {detailsVisible && (
                <Collapsible trigger={t('Statistic')}>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: t('statisticDescription'),
                    }}
                  />
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: t('statisticPoints'),
                    }}
                  />
                </Collapsible>
              )}
              <MainButton
                width="100%"
                mb={15}
                mt={30}
                type="submit"
                disabled={submitting}
              >
                {t('Save preferences')}
              </MainButton>
            </form>
          )}
        />
      </Box>
    </>
  );
};

export default PreferencesModal;
