import validator from 'validator';
import moment from 'moment-timezone';
import i18n from 'i18n';
import { get, isEmpty } from 'lodash';

export const validateIfEqual = (field, fieldValue, vtor) => (value, values) =>
  get(values, field) === fieldValue ? vtor(value, values) : undefined;

export const composeValidators = (...validators) => (value, values) =>
  validators.reduce(
    (error, _validator) => error || _validator(value, values),
    undefined,
  );

export const mustBeValidEmail = value =>
  validator.isEmail(value) ? undefined : i18n.t('errors:Invalid email address');

// eslint-disable-next-line
export const isValidZipCode = value => {
  if (value === null || value === '' || value === undefined) {
    return undefined;
  }

  // Both 90-133 & 01001
  // /\b(?!01000)(?!99999)(0[1-9]\d{3}|[1-9]\d{4})\b($|\s)?$|^[0-9]{2}-[0-9]{3}($|\s)?$/,

  const regValidator = new RegExp(
    /\b(?!01000)(?!99999)(0[1-9]\d{3}|[1-9]\d{4})\b($|\s)?$/,
  );
  if (!regValidator.test(value)) return i18n.t('errors:invalid_zip_code');
  return undefined;
};

export const maxValue = max => value => {
  return isEmpty(value) || value.length <= max
    ? undefined
    : i18n.t('errors:Max length', { count: max });
};

export const required = value =>
  value ? undefined : i18n.t('errors:This field is required');

export const isNumber = value =>
  validator.isNumeric(value)
    ? undefined
    : i18n.t('errors:Invalid street number');

export const equalToPassword = (value, values) =>
  value === values.password
    ? undefined
    : i18n.t("errors:Passwords don't match");

export const checkTime = (value, { from, to }) => {
  const timeFrom = moment(from, 'HH:mm');
  const timeTo = moment(to, 'HH:mm');
  if (timeTo.format('HH:mm') === '00:00') {
    timeTo.add(1, 'days');
  }

  if (moment.duration(timeTo.diff(timeFrom)).asHours() < 1) {
    return i18n.t('errors:Incorrect booking time');
  }

  return undefined;
};

export const mustBeLaterThanCurrentHour = (value, { to }) => {
  const fromTime = moment(value, 'HH:mm');
  const toTime = moment(to, 'HH:mm');
  if (to === '00:00') {
    toTime.add(1, 'days');
  }

  if (moment.duration(toTime.diff(fromTime)).asHours() <= 0) {
    return i18n.t('errors:Start time must be before end time');
  }

  return undefined;
};

export const mustBe1hourDuration = (value, { from }) => {
  const fromTime = moment(from, 'HH:mm');
  const toTime = moment(value, 'HH:mm');
  if (value === '00:00') {
    toTime.add(1, 'days');
  }

  if (moment.duration(toTime.diff(fromTime)).asHours() < 1) {
    return i18n.t('errors:Minimum duration is 1h');
  }

  return undefined;
};

export const isValidNip = nip => {
  if (typeof nip === 'string') {
    nip = nip.replace(/[ -]/gi, '');

    const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    let sum = 0;
    const controlNumber = parseInt(nip.substring(9, 10), 10);
    const weightCount = weight.length;

    for (let i = 0; i < weightCount; i += 1) {
      sum += parseInt(nip.substr(i, 1), 10) * weight[i];
    }
    if (sum % 11 !== controlNumber) {
      return i18n.t('validator:Incorrect VAT Number');
    }
  }

  return false;
};

export const apiErrorMapper = errors => {
  const apiErrors = {};

  Object.keys(errors).forEach(errKey => {
    if (errKey.indexOf('.') !== -1) {
      const splittedKey = errKey.split('.');
      const formGroup = splittedKey[0];
      const field = splittedKey[1];

      if (apiErrors[formGroup]) {
        apiErrors[formGroup][field] = [i18n.t(`errors:${errors[errKey][0]}`)];
      } else {
        apiErrors[formGroup] = {
          [field]: [i18n.t(`errors:${errors[errKey][0]}`)],
        };
      }
    } else {
      apiErrors[errKey] = [i18n.t(`errors:${errors[errKey][0]}`)];
    }
  });
  return apiErrors;
};
