import styled from '@emotion/styled';
import { breakpoint } from 'styles/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  background: #3b5998;
  text-transform: uppercase;
  color: #fff;
  transition: margin-top 0.5s ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};

  margin-top: ${props => (props.visible ? 0 : '-100vh')};
  padding: 15px 0;
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 9999;
  padding: 0;

  @media ${breakpoint.md} {
    margin-top: ${props => (props.visible ? 0 : '-60px')};
    opacity: ${props => (props.visible ? 1 : 0)};
    position: relative;
    padding: 15px 10px;
    min-height: 60px;
    height: auto;
  }
`;

export const Close = styled.button`
  background: transparent url('/images/icons/close.svg') no-repeat 50% 50% /
    14px;
  border: none;
  position: absolute;

  cursor: pointer;
  width: 36px;
  height: 36px;
  top: 15px;
  right: 15px;
  z-index: 10;

  &:focus {
    outline: none;
  }

  @media ${breakpoint.md} {
    width: 24px;
    height: 24px;
    right: 0;
    top: calc(50% - 12px);
  }
`;

export const ButtonText = styled.button`
  background-color: transparent;
  color: #ffffff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;

  font-size: 16px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  padding: 45px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  line-height: 24px;

  b {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    display: block;
    margin-bottom: 30px;
  }

  &:focus {
    outline: none;
  }

  @media ${breakpoint.md} {
    padding: 0;
    margin: 0 auto;
    font-size: 16px;
    line-height: 18px;
    font-weight: 300;
    letter-spacing: 1px;
    position: relative;
    width: auto;
    height: auto;
    display: block;

    b {
      font-size: 18px;
      font-weight: 700;
      display: block;
      margin-bottom: 5px;
    }
  }
`;
