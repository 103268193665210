import { extendObservable, action } from 'mobx';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import has from 'lodash/has';
// eslint-disable-next-line import/no-cycle
import { API, APIRoutes } from 'api';
import { searchStore, routingStore, userStore, authStore } from 'stores';
import routes from 'routes';
import { formatDateTimeForAPI } from 'utils/datetimeHelpers';
import i18n from 'i18n';
import routerStore from './routerStore';

const initialState = {
  isLoading: false,
  error: null,
  bookings: [],
  currentBooking: {},
  room: {},
  params: {},
  initialized: false,
  step: null,
  submitting: false,
  slug: null,
  userData: {},
  order: {},
};

export class BookingStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action reset = () => {
    Object.keys(initialState).forEach(key => {
      this[key] = initialState[key];
    });
    sessionStorage.removeItem('ballroom:booking');
  };

  storeBooking = (slug, params) => {
    const booking = { [slug]: queryString.stringify(params) };
    sessionStorage.setItem('ballroom:booking', JSON.stringify(booking));
  };

  getStoredBooking = () => sessionStorage.getItem('ballroom:booking');

  getStoredUserData = () => sessionStorage.getItem('ballroom:booking:user');

  noBookingError = slug => routingStore.replace(routes.description(slug));

  isUserProfileComplete = () => {
    if (has(this.userData, 'billingAddress')) {
      const ba = this.userData.billingAddress;
      const requiredAttributes = [
        'name',
        'firstName',
        'lastName',
        'vatNumber',
        'country',
        'street',
        'streetNumber',
        'city',
      ];

      return requiredAttributes.every(k => k in ba);
    }

    return false;
  };

  @action
  clearStoredData = () => {
    sessionStorage.removeItem('ballroom:booking');
    sessionStorage.removeItem('ballroom:booking:user');
    this.userData = initialState.userData;
    this.params = initialState.params;
  };

  @action fetchBookingSummary = async () => {
    try {
      const qp = queryString.parse(routingStore.location.search);
      const {
        data: { data: order },
      } = await API(APIRoutes.orders.details(qp.bid));

      this.order = order;

      this.clearStoredData();
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  @action handleQueryParamsChange = () => {
    try {
      const { s } = queryString.parse(routingStore.location.search, {
        parseNumbers: true,
      });
      this.step = s;
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  @action modifyBillingAddress = () => {
    this.params.s = 1;
    routingStore.push(routes.booking.process(this.slug, this.params));
  };

  @action submitBooking = async () => {
    this.submitting = true;
    try {
      const redirectUrl = `${
        window.location.origin
      }${routes.booking.summary(this.slug, { bid: this.params.bid })}`;

      await API.post(APIRoutes.orders.submit, {
        cartId: this.params.bid,
        email: this.userData.email,
        billingAddress: this.userData.billingAddress,
        redirectUrl,
      });

      // as me omit paymentPage - see BLR-346
      window.location.href = redirectUrl;
    } catch (error) {
      if (error.message === 'invalid_zip_code') {
        toast.info(i18n.t('toastify:Form contains some errors'));
      } else {
        this.clearCart();
      }
    } finally {
      this.submitting = false;
    }
  };

  @action fetchRoom = async slug => {
    this.isLoading = true;
    try {
      await searchStore.fetchRoomBySlug(slug);
      this.room = searchStore.currentRoom;
      // this.params = queryParams;
      this.initialized = true;
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    } finally {
      this.isLoading = false;
    }
  };

  @action submitStep = async formValues => {
    this.submitting = true;

    try {
      if (this.step === 1) {
        if (
          formValues &&
          formValues.billingAddress &&
          formValues.billingAddress.type === 'private'
        ) {
          this.userData = {
            ...formValues,
            billingAddress: {
              ...formValues.billingAddress,
              name: `${formValues.billingAddress.firstName} ${formValues.billingAddress.lastName}`,
            },
          };
        } else {
          this.userData = formValues;
        }

        if (authStore.isAuthenticated) {
          if (
            formValues &&
            formValues.billingAddress &&
            formValues.billingAddress.type === 'private'
          ) {
            await userStore.updateProfile({
              ...formValues,
              billingAddress: {
                ...formValues.billingAddress,
                name: `${formValues.billingAddress.firstName} ${formValues.billingAddress.lastName}`,
              },
            });
          } else {
            await userStore.updateProfile(formValues);
          }
        } else {
          sessionStorage.setItem(
            'ballroom:booking:user',
            JSON.stringify(this.userData),
          );
        }
        this.params.s = 2;
        this.step = 2;
        routingStore.push(routes.booking.process(this.slug, this.params));
        this.storeBooking(this.slug, this.params);
      }
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    } finally {
      this.submitting = false;
    }
  };

  @action clearCart = () => {
    delete this.params.bid;
    routingStore.replace(
      `${routes.description(this.slug)}?${queryString.stringify(this.params)}`,
    );
    sessionStorage.removeItem('ballroom:booking');
    toast.info(i18n.t('toastify:Your booking time limit expired.'));
  };

  @action initBookingAfterSignIn = async () => {
    this.isLoading = true;
    try {
      this.userData = { ...userStore.profile };
      if (this.isUserProfileComplete()) {
        this.params.s = 2;
        routingStore.push(routes.booking.process(this.slug, this.params));
      }
    } catch (error) {
      if (error.status === 'error') {
        this.clearCart();
      }
    } finally {
      this.isLoading = false;
    }
  };

  @action initBooking = async (slug, params) => {
    this.isLoading = true;

    try {
      const queryParams = queryString.parse(params, {
        parseNumbers: true,
      });
      this.params = queryParams;
      this.step = queryParams.s;
      this.slug = slug;

      const {
        data: { data: order },
      } = await API(APIRoutes.orders.details(this.params.bid));
      this.order = order;

      const {
        data: { data: booking },
      } = await API(
        // eslint-disable-next-line no-underscore-dangle
        APIRoutes.bookings.details(order.bookings[0]._id),
      );
      this.currentBooking = booking;

      // redirect
      if (!this.step || this.step > 2) {
        delete queryParams.s;
        routingStore.replace(
          `${routes.description(slug)}?${queryString.stringify(queryParams)}`,
        );
        this.reset();
      }

      if (
        !!(
          queryParams.s &&
          queryParams.from &&
          queryParams.to &&
          queryParams.city
        ) &&
        slug
      ) {
        await searchStore.fetchRoomBySlug(slug);
        this.room = searchStore.currentRoom;
        this.params = queryParams;

        if (!authStore.isAuthenticated) {
          if (
            Object.hasOwnProperty.call(sessionStorage, 'ballroom:booking:user')
          ) {
            this.userData = JSON.parse(await this.getStoredUserData());
          }
        } else {
          this.userData = { ...userStore.profile };
        }

        if (this.step === 2 && order && order.state === 'COMPLETE') {
          routerStore.push(
            routes.booking.summary(this.slug, { bid: this.params.bid }),
          );
        }

        this.initialized = true;
      }
    } catch (error) {
      if (error.status === 'error') {
        delete this.params.bid;
        routingStore.replace(
          `${routes.description(slug)}?${queryString.stringify(this.params)}`,
        );
        sessionStorage.removeItem('ballroom:booking');
        if (!error.message) {
          toast.info(i18n.t('toastify:Your booking time limit expired.'));
        } else {
          toast.info(
            i18n.t(
              'toastify:Something went wrong. Contact us to book the room.',
            ),
          );
        }
      } else {
        console.log(error); // eslint-disable-line no-console
      }
    } finally {
      this.isLoading = false;
    }
  };

  @action startBooking = async (slug, params) => {
    try {
      if (Object.hasOwnProperty.call(sessionStorage, 'ballroom:booking')) {
        const storedBooking = JSON.parse(this.getStoredBooking());

        if (storedBooking[slug]) {
          const currentParams = queryString.parse(params);
          const storedParams = queryString.parse(storedBooking[slug]);

          if (
            currentParams.capacity === storedParams.capacity &&
            currentParams.date === storedParams.date &&
            currentParams.from === storedParams.from &&
            currentParams.to === storedParams.to
          ) {
            await this.initBooking(slug, storedBooking[slug]);
            routingStore.push(routes.booking.process(slug, this.params));
            return;
          }
        }
      }

      this.room = searchStore.currentRoom;
      this.params = queryString.parse(params);
      this.slug = slug;

      const from = formatDateTimeForAPI(this.params.date, this.params.from);
      const to = formatDateTimeForAPI(this.params.date, this.params.to);

      const {
        data: { booking },
      } = await API.post(APIRoutes.orders.create, {
        booking: {
          // eslint-disable-next-line no-underscore-dangle
          bookableObjectId: this.room._id,
          from,
          to,
          numberOfUsers: this.params.capacity,
        },
      });

      // eslint-disable-next-line no-underscore-dangle
      this.params.bid = booking._orderId;
      this.params.s = 1;
      this.currentBooking = booking;

      if (authStore.isAuthenticated) {
        this.step = 1;
        this.userData = { ...userStore.profile };
        if (this.isUserProfileComplete()) {
          this.params.s = 2;
          routingStore.push(routes.booking.process(slug, this.params));
        } else {
          routingStore.push(routes.booking.process(slug, this.params));
        }
      } else {
        this.step = 1;
        routingStore.push(routes.booking.process(slug, this.params));
      }
      this.storeBooking(slug, this.params);
      this.initialized = true;
    } catch (error) {
      toast.error(error.message);
    }
  };

  @action fetchBookings = async () => {
    this.isLoading = true;
    try {
      const {
        data: { data },
      } = await API(APIRoutes.bookings.list);
      this.bookings = data;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchBookingById = async id => {
    this.isLoading = true;
    try {
      const { data } = await API(`${APIRoutes.bookings.details(id)}`);
      this.currentBooking = data;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action updateBooking = async (bookingId, from, to) => {
    this.isLoading = true;
    try {
      await API.patch(`${APIRoutes.booking.update(bookingId)}`, {
        from,
        to,
      });
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action changeBookingSchedule = async (bookingId, dateRange, callback) => {
    try {
      await API.patch(APIRoutes.bookings.reschedule(bookingId), dateRange);
      toast.info(i18n.t('toastify:Your booking schedule has been changed.'));
      if (typeof callback === 'function') {
        callback();
      }
      await userStore.fetchBookings();
    } catch (error) {
      toast.error(error.message);
    }
  };

  @action cancelBooking = async (bookingId, callback) => {
    try {
      await API.post(APIRoutes.bookings.cancelReservation(bookingId));
      toast.info(i18n.t('toastify:Your booking has been cancelled.'));
      if (typeof callback === 'function') {
        callback();
      }
      await userStore.fetchBookings();
    } catch (error) {
      toast.error(error.message);
    }
  };
}

export default new BookingStore();
