import React, { useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { slide as Menu } from 'react-burger-menu';
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { css } from '@emotion/core';
import { Image, Flex, Button } from 'rebass';
import routes from 'routes';
import i18n, { availableLanguages } from 'i18n';
import * as Styled from './style';

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '26px',
    height: '20px',
    right: '30px',
    top: '26px',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    right: '30px',
    top: '26px',
  },
  bmCross: {
    background: 'transparent',
    width: 22,
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
    width: '90%',
    maxWidth: '400px',
  },
  bmMenu: {
    // background: '#D9A248',
    background: '#3E291C',
    padding: '0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#3E291C',
    // fill: '#D9A248',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: 0,
  },
  bmItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  bmOverlay: {
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.8)',
  },
};

const MobileNav = inject(
  'authStore',
  'userStore',
  'routingStore',
)(
  observer(
    ({ t, authStore: { logout, isAuthenticated }, userStore: { profile } }) => {
      const menu = useRef();

      const userSection = () => {
        if (!isAuthenticated) {
          return (
            <Styled.MobileNavItem
              to={routes.auth.signIn}
              onClick={() => {
                menu.current.state.isOpen = false;
              }}
            >
              {t('Sign in')}
            </Styled.MobileNavItem>
          );
        }

        return (
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Flex sx={{ overflowWrap: 'anywhere', maxWidth: '250px' }}>
              <span>{profile.name || profile.email}</span>
              {profile.isPremium ? (
                <img
                  style={{ marginLeft: '5px' }}
                  width="16px"
                  height="16px"
                  src="/images/icons/diamond.svg"
                  alt="PREMIUM"
                />
              ) : null}
            </Flex>
            {/* <Select
              options={userSelectOptions}
              onChange={userSelectChangeHandler}
              placeholder={profile.name || profile.email}
            /> */}
            <Flex alignItems="center" justifyContent="space-around">
              <Link
                to={routes.account}
                onClick={() => {
                  menu.current.state.isOpen = false;
                }}
              >
                <Image src="/images/icons/settings.svg" />
              </Link>
              <Button
                onClick={() => {
                  menu.current.state.isOpen = false;
                  logout();
                }}
                variant="icon"
                ml="10px"
              >
                <Image src="/images/icons/logout.svg" />
              </Button>
            </Flex>
          </Flex>
        );
      };

      return (
        <Menu
          ref={menu}
          right
          isOpen={false}
          styles={styles}
          customBurgerIcon={<Image src="/images/icons/burger.svg" />}
          customCrossIcon={<Image src="/images/icons/close.svg" />}
          disableAutoFocus
        >
          <Styled.UserProfileMobile column={!isAuthenticated}>
            {userSection()}
            {!isAuthenticated && (
              <Styled.MobileNavItem
                to={routes.auth.register}
                onClick={() => {
                  menu.current.state.isOpen = false;
                }}
              >
                {t('Sign up')}
              </Styled.MobileNavItem>
            )}
          </Styled.UserProfileMobile>
          <Styled.Separator />

          {i18n.language === 'de' && (
            <Styled.MobileNavItem
              to={routes.blog}
              onClick={() => {
                menu.current.state.isOpen = false;
              }}
            >
              {t('Blog')}
            </Styled.MobileNavItem>
          )}
          <Styled.MobileNavItem
            to={routes.howToBook}
            onClick={() => {
              menu.current.state.isOpen = false;
            }}
          >
            {t('How to book')}?
          </Styled.MobileNavItem>
          <Styled.MobileNavItem
            to={routes.rooms}
            onClick={() => {
              menu.current.state.isOpen = false;
            }}
          >
            {t('Our locations')}
          </Styled.MobileNavItem>

          {/* see https://goodylabs.atlassian.net/browse/BLR-377
          <Styled.MobileNavItem
            to={routes.premium}
            onClick={() => {
              menu.current.state.isOpen = false;
            }}
          >
            {t('Premium')}
          </Styled.MobileNavItem> */}
          <Styled.MobileNavItem
            to={routes.contact}
            onClick={() => {
              menu.current.state.isOpen = false;
            }}
          >
            {t('Contact')}
          </Styled.MobileNavItem>
          <Styled.Separator />
          <Flex
            alignItems="center"
            justifyContent="center"
            sx={css`
              &:focus {
                outline: none;
              }
            `}
          >
            {availableLanguages.map(lang => (
              <Button
                key={lang.label}
                type="button"
                onClick={() => i18n.changeLanguage(lang.value)}
                sx={css`
                  background: transparent;
                  border: none;
                  padding: 10px;
                  cursor: pointer;
                  &:focus {
                    outline: none;
                  }
                `}
              >
                <img
                  src={`/images/flags/${lang.value}.svg`}
                  alt={lang.label}
                  style={{
                    width: '26px',
                    height: '20px',
                    objectFit: 'cover',
                    borderRadius: '3px',
                  }}
                />
              </Button>
            ))}
          </Flex>
        </Menu>
      );
    },
  ),
);

const enhance = compose(withRouter, withTranslation('navbar'));

export default enhance(MobileNav);
