import { extendObservable, action } from 'mobx';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { API, APIRoutes } from 'api';
import { routingStore } from 'stores';
// import sampleSize from 'lodash/sampleSize';
import routes from 'routes';

const initialState = {
  roomsList: [],
  similarRoomsList: [],
  isLoading: false,
  error: null,
  filters: {},
  currentRoom: {},
  notFound: false,
  submitting: false,
};

export class SearchStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action fetchRooms = async () => {
    this.isLoading = true;
    this.filters = queryString.parse(routingStore.location.search);
    // hack for 00:00 hour
    if (this.filters.to === '00:00') {
      this.filters.to = '24:00';
    }
    const query = queryString.stringify(this.filters);

    try {
      const {
        data: { data },
      } = await API(`${APIRoutes.rooms.list}?${query}&addressActive=true`);

      // const largeRooms = [];
      // const smallRooms = [];
      // const capacity = parseInt(this.filters.capacity, 10);

      // data.forEach(r => {
      //   if (r.surfaceArea >= 50) {
      //     largeRooms.push(r);
      //   } else {
      //     smallRooms.push(r);
      //   }
      // });

      // let results = [];
      // // 1-3 osoby pokazujemy 4 małe sale losowo + 2 duże losowo
      // if (capacity < 4) {
      //   results = [...sampleSize(smallRooms, 4), ...sampleSize(largeRooms, 2)];
      // }
      // // 4+ osoby pokazujemy 4 duże sale losowo + 2 małe losowo
      // else {
      //   results = [...sampleSize(largeRooms, 4), ...sampleSize(smallRooms, 2)];
      // }

      // this.roomsList = results;
      this.roomsList = data;
      this.error = null;
    } catch (e) {
      this.error = e.message;
      this.roomsList = [];
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchSimilarRooms = async () => {
    this.isLoading = true;
    this.filters = queryString.parse(routingStore.location.search);
    // hack for 00:00 hour
    if (this.filters.to === '00:00') {
      this.filters.to = '24:00';
    }
    const query = queryString.stringify(this.filters);

    try {
      const {
        data: { data },
      } = await API(`${APIRoutes.rooms.similar}?${query}`);
      this.similarRoomsList = data.filter(item => item.items.length);
    } catch (e) {
      this.error = e;
      this.similarRoomsList = [];
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchRoomBySlug = async roomId => {
    this.isLoading = true;
    try {
      const queryParams = queryString.parse(routingStore.location.search);
      let APIpath = APIRoutes.rooms.details(roomId);
      if (queryParams.date && queryParams.from && queryParams.to) {
        const quueryPayload = {
          date: queryParams.date,
          capacity: queryParams.capacity,
          from: queryParams.from,
          to: queryParams.to === '00:00' ? '24:00' : queryParams.to,
        };

        APIpath = `${APIRoutes.rooms.details(roomId)}?${queryString.stringify(
          quueryPayload,
        )}`;
      }
      const {
        data: { data },
      } = await API(APIpath);
      this.currentRoom = data;
    } catch (e) {
      this.notFound = true;
      this.error = e;
      toast.error(e.message);
    } finally {
      this.isLoading = false;
    }
  };

  @action
  submitSearchForm = async formValues => {
    this.submitting = true;
    try {
      delete formValues.day;
      routingStore.push(routes.search(formValues));
    } catch (error) {
      console.warn(error); // eslint-disable-line
    } finally {
      this.submitting = false;
    }
  };

  @action reset = () => {
    this.currentRoom = {};
    this.notFound = false;
    this.error = null;
  };
}

export default new SearchStore();
