import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { formatDateFromAPI } from 'utils/datetimeHelpers';
import { currencyFormat } from 'utils/staticValues';
// import i18n from 'i18n';
import * as Styled from './style';

const Item = ({ order, t, paymentStatus }) => (
  <Styled.Item alignItems="center" justifyContent="space-between">
    <Styled.Left>
      <Styled.Title>
        <span>
          <Styled.RoomName>{order.description}</Styled.RoomName>
          <Styled.ArrowBorder />
        </span>
      </Styled.Title>

      <Styled.Column
        sx={{
          minWidth: '250px',
          '@media (max-width: 950px)': { minWidth: '200px' },
        }}
        width={['100%', '100%', '100%', '35%']}
      >
        <Styled.ColumnLabel>{t('order:Date')}</Styled.ColumnLabel>
        <Styled.ColumnValue>
          {formatDateFromAPI(order.createdAt, 'dddd / D MMMM / YYYY')}
        </Styled.ColumnValue>
      </Styled.Column>

      <Styled.Column width={['50%', '50%', '50%', 'auto']}>
        <Styled.ColumnLabel>{t('order:Price')}</Styled.ColumnLabel>
        <Styled.ColumnValue>
          {order.priceData.price / 100}&nbsp;
          {currencyFormat[order.priceData.currency]}
        </Styled.ColumnValue>
      </Styled.Column>

      <Styled.Column
        sx={{
          '@media (max-width: 950px)': { minWidth: '200px' },
        }}
        width={['50%', '50%', '50%', '100px']}
      >
        <Styled.ColumnLabel>{t('booking:Payment status')}</Styled.ColumnLabel>
        <Styled.ColumnValue>
          {t(`paymentStatus:${paymentStatus}`)}
        </Styled.ColumnValue>
      </Styled.Column>
    </Styled.Left>
  </Styled.Item>
);

Item.propTypes = {
  order: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  paymentStatus: PropTypes.string.isRequired,
};

export default withTranslation('account')(Item);
