import React from 'react';
import i18n from 'i18n';
import IconDetail from 'components/IconDetail';
import { SimilarRoomHeaderTitle, AdditionalInfo } from 'styles/globalStyles';
import * as Styled from './style';

const SimilarRoomTile = ({
  image,
  similarRoomName,
  similarRoomAddress,
  similarRoomCapacity,
  similarRoomAvailability,
  similarRoomSurface,
  similarRoomInfo,
  similarRoomPrice,
  similarRoomCurrency,
  to,
}) => {
  return (
    <Styled.Card to={to}>
      <Styled.Image src={image} />
      <SimilarRoomHeaderTitle absolute topPos="10px">
        <Styled.Title as="h1">{similarRoomAvailability}</Styled.Title>
        <Styled.Price>
          {similarRoomPrice}&nbsp;{similarRoomCurrency}
        </Styled.Price>
      </SimilarRoomHeaderTitle>
      <Styled.SimilarRoomWrapper>
        <Styled.NameSurfaceWrapper>
          <Styled.SimilarRoomName>{similarRoomName}</Styled.SimilarRoomName>
          <Styled.SimilarRoomSurface>
            {similarRoomSurface} m
            <sup style={{ fontSize: 'xx-small', verticalAlign: 'super' }}>
              2
            </sup>
            <Styled.EmphLine src="/images/lineEmph.svg" alt="" />
          </Styled.SimilarRoomSurface>
        </Styled.NameSurfaceWrapper>

        <IconDetail
          mb={[1, 1, 1, 1, 2]}
          icon="/images/icons/addressIcon.svg"
          text={similarRoomAddress}
          color="#b1b8bc"
        />
        {similarRoomInfo && (
          <AdditionalInfo
            similarTile
            dangerouslySetInnerHTML={{ __html: similarRoomInfo }}
          />
        )}
        {similarRoomCapacity && (
          <IconDetail
            mb={[1, 1, 1, 1, 2]}
            icon="/images/icons/capacityIcon.svg"
            label="max_people"
            text={i18n.t('person', { count: similarRoomCapacity })}
            color="#b1b8bc"
          />
        )}
      </Styled.SimilarRoomWrapper>
    </Styled.Card>
  );
};

export default SimilarRoomTile;
